import "./Button.css";
interface Props {
  border?: string;
  color?: string;
  children?: React.ReactNode;
  height?: string;
  onClick?: () => void;
  radius?: string;
  width?: string;
  variant?: "primary" | "outline";
  type?: "submit";
  disabled?: boolean;
}

const Button: React.FC<Props> = (props) => {
  const {
    border,
    color,
    children,
    height,
    onClick,
    radius,
    width,
    type="submit",
    variant = "primary",
    disabled,
  } = props;
  return (
    <button
     {...props}
      className={`buttons ${
        variant === "primary"
          ? disabled
            ? "custom-button-primary-disabled"
            : "custom-button-primary"
          : "custom-button-outline"
      } `}
      type="submit"
    >
      {children}
    </button>
  );
};

export default Button;
