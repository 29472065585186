
export interface Crawler {
    name: string;
    slug: string;
}

export interface CrawlerProps {
    crawlers: Crawler[];
}

export interface RunInfo {
    run_id: string;
    started_at: string;
    module: string;
    ended_at: string;
    started_at_date: string;
    ended_at_date: string;
    status: string;
    uploaded: boolean;
    is_verified: boolean;
    errors: string[]
}

export interface ComparableItem {
    attribute: string;
    empty: number,
    null: number,
    zero: number,
    value: number;
    average: number;
    previous: number;
    low: number;
    high: number;
    is_valid: boolean;
}

export interface PriceItem {
    sku: string;
    currency: string; //price_jpy
    value: number | null;
    average: number | null;
    previous: number | null;
    low: number | null;
    high: number | null;
    is_valid: boolean;
}

export interface PriceInfo {
    previous: number | null;
    current: number | null;
    average: number | null;
    low: number | null;
    high: number | null;
    is_valid: boolean;
}

export const NoData = () => {
    return (<div className="no-data-message">
        Requested Data is not yet available, please wait
    </div>)
}