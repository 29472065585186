import Button from "../common/Buttons/Button";
import "./Signup.css";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const navigate = useNavigate();
  const handleNavigateSignIn = () => {
    navigate("/login");
  };

  return (
    <div className="signupcontainer">
      <div className="main">
        <h2>Sign Up to getting started</h2>
        <p className="pera">Enter your details to proceed further</p>
      </div>
      <div className="form">
        <form>
          <div className="label">
            <label>Full Name</label>
          </div>
          <div className="field">
            <input type="text" required name="name" id="name" />
          </div>
          <div className="label">
            <label>Email</label>
          </div>
          <div className="field">
            <input type="email" required name="email" id="email" />
          </div>
          <div className="label">
            <label>Password</label>
          </div>
          <div className="field">
            <input type="password" required name="password" id="password" />
          </div>
          <div className="label">
            <label>Confirm Password</label>
          </div>
          <div className="field">
            <input type="password" required name="password" id="password" />
          </div>
          <div className="remember">
            <div className="check">
              <input type="checkbox" name="checkbox" id="checkbox" />I agree
              with terms & conditions
            </div>
          </div>
          <div className="form-buttons">
            <Button type="submit">Sign Up</Button>
            <Button variant="outline" onClick={handleNavigateSignIn}>
              Sign In
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
