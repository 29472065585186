import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Uploads.css";
import { RunInfo } from "../common/common";
import { ReactComponent as QuestionSvg } from "../../images/question.svg";
import ToolTip from "../common/Tooltip";

interface RunsComponentProps {
  runs: RunInfo[];
  selectedRuns: string[];
  setSelectedRuns: React.Dispatch<React.SetStateAction<string[]>>;
}
const collectio_running = {
  borderRadius: "12px",
  backgroundColor: "#eceff4",
  color: "#455265",
  padding: "2px 10px",
  display: "inline-block",
  fontWeight: "bold",
  fontSize: "12px",
};
const upload_done = {
  borderRadius: "12px",
  backgroundColor: "#e6f7ef",
  color: "#12b268",
  padding: "2px 10px",
  display: "inline-block",
  fontWeight: "bold",
  fontSize: "12px",
};
const upload_error = {
  borderRadius: "12px",
  backgroundColor: "#ff010112",
  color: "#ff0101",
  padding: "2px 10px",
  display: "inline-block",
  fontWeight: "bold",
  fontSize: "12px",
};

const collection_aborted = {
  borderRadius: "12px",
  backgroundColor: "#ff77001c",
  color: "#ff7700",
  padding: "2px 10px",
  display: "inline-block",
  fontWeight: "bold",
  fontSize: "12px",
};
export const UploadTable: React.FC<RunsComponentProps> = (props) => {
  const { runs, selectedRuns, setSelectedRuns } = props;
  const handleOnCheckRun = (e: React.ChangeEvent<HTMLInputElement>) => {
    const run_id = e.target.value;
    if (e.target.checked) {
      if (!selectedRuns.includes(run_id)) {
        setSelectedRuns((prevSelectedRuns) => [...prevSelectedRuns, run_id]);
      }
    } else {
      setSelectedRuns((prevSelectedRuns) =>
        prevSelectedRuns.filter((id) => id !== run_id)
      );
    }
  };
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: "64vh", overflowY: "auto" }}
    >
      <Table className="runs-table" aria-label="simple table">
        <TableHead className="runs-table-head">
          <TableRow>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Crawler
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Run ID
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Slug
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Status
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Upload
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                     Trigger a data run upload.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Uploaded
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                     True if data uploaded; False otherwise.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {runs.map((run) => (
            <TableRow key={run.run_id}>
              <TableCell component="th" scope="row">
                {run.module}
              </TableCell>
              <TableCell component="th" scope="row">
                {run.run_id}
              </TableCell>
              <TableCell>{run.started_at_date}</TableCell>
              <TableCell>
                {" "}
                <span
                  style={
                    run.status == "collection_aborted"
                      ? collection_aborted
                      : [
                        "upload_done",
                        "review_done",
                        "collection_done",
                      ].includes(run.status)
                        ? upload_done
                        : [" 'upload_error'", "collection_error"].includes(
                          run.status
                        )
                          ? upload_error
                          : collectio_running
                  }
                >
                  {run.status}
                </span>
              </TableCell>
              <TableCell>
                <input
                  style={{ cursor: "pointer" }}
                  type="checkbox"
                  id={run.run_id}
                  name="verify"
                  value={run.run_id}
                  onChange={handleOnCheckRun}
                  defaultChecked
                ></input>
              </TableCell>
              <TableCell
                style={{
                  fontWeight: run.uploaded ? "normal" : "bold",
                  color: run.uploaded ? "lightgreen" : "red",
                }}
              >
                <span className={run.uploaded ? "change-clr" : "clr-red"}>
                  {run.uploaded ? "True" : "False"}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
