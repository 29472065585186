import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../common/common.css";
import { ComparableItem } from "../common/common";
import { ReactComponent as QuestionSvg } from "../../images/question.svg";
import ToolTip from "../common/Tooltip";

interface ComparableProps {
  comparables: ComparableItem[];
  filter: boolean | null | "null";
}

export const ComparableTable: React.FC<ComparableProps> = ({
  comparables,
  filter,
}) => {
  if (typeof filter === "boolean" || filter == "null") {
    comparables = comparables.filter((comp) => {
      // @ts-ignore
      if (filter === "null") {
        console.log(filter, 1);
        return comp.is_valid === null;
      } else {
        console.log(filter, 2);
        return comp.is_valid === filter;
      }
    });
  }
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: "64vh", overflowY: "auto" }}
    >
      <Table className="runs-table" aria-label="simple table">
        <TableHead className="runs-table-head">
          <TableRow>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Attribute
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Current
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Current count of valid values for a given run.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Null
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Current count of null values for a given run.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Empty
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Current count of empty values for a given run.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Zero
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Current count of zero values for a given run.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Previous
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    count of valid values in the previous run
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              AVG
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    average count of valid values over the last 3 valid runs.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Low
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Valid values average count *minus* 2 standard deviations.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              High
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Valid values average count *plus* 2 standard deviations.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              OK
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    True if the current valid values count is between low and
                    high; False otherwise.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {comparables.map((comp, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {comp.attribute}
              </TableCell>
              <TableCell>{comp.value}</TableCell>
              <TableCell>{comp.null}</TableCell>
              <TableCell>{comp.empty}</TableCell>
              <TableCell>{comp.zero}</TableCell>
              <TableCell>
                {comp.previous !== undefined && comp.previous !== null
                  ? Math.round(Number(comp.previous.toFixed(2)))
                  : "NULL"}
              </TableCell>
              <TableCell>
                {comp.average !== undefined && comp.average !== null
                  ? Math.round(Number(comp.average.toFixed(2)))
                  : "NULL"}
              </TableCell>
              <TableCell>
                {comp.low !== undefined && comp.low !== null
                  ? Math.round(Number(comp.low.toFixed(2)))
                  : "NULL"}
              </TableCell>
              <TableCell>
                {comp.high !== undefined && comp.high !== null
                  ? Math.round(Number(comp.high.toFixed(2)))
                  : "NULL"}
              </TableCell>

              {/* <TableCell>{comp.is_valid ? "True" : "False"}</TableCell> */}
              <TableCell
                style={{
                  fontWeight: comp.is_valid ? "normal" : "bold",
                  color: comp.is_valid ? "lightgreen" : "red",
                }}
              >
                <span
                  className={
                    comp.is_valid
                      ? "change-clr"
                      : comp.is_valid == false
                      ? "clr-red"
                      : "review-clr"
                  }
                >
                  {comp.is_valid
                    ? "True"
                    : comp.is_valid == false
                    ? "False"
                    : "NULL"}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
