"use client"

import Tooltip, {TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#0a2540',
    boxShadow: '0 3px 16px 0 rgba(10, 37, 64, 0.1)',
    border: 'solid 1px rgba(213, 216, 238, 0.34)',
    borderRadius: 8,
    fontSize: 12,
    fontFamily: 'inherit',
    padding: '0 17px',
    maxWidth: 500,
    lineHeight:1.5,
  },
}));



const ToolTip:React.FC<TooltipProps> = ({ title, placement, children}) => {

  return (
    <LightTooltip title={title} placement={placement}>
      {children}
    </LightTooltip>
  );
}

export default ToolTip;