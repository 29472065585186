import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginComponent } from "./components/login/Login";
import { FrontComponent } from "./components/Front";
import { Comparables } from "./components/comparables/Comparables";
import RecoverPasssword from "./components/recover-password/Recover-passsword";
import SignUp from "./components/signup/Signup";
export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/" element={<FrontComponent />} />
        <Route path="/recover-password" element={<RecoverPasssword />} />
        <Route path="/sign-up" element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
