import Button from "../common/Buttons/Button";
import "./Recover.css"
export default function RecoverPasssword() {
  return (
    <div className="recovercontainer">
    <div className="main">
      <h2>Lost your password? <br />
      Enter your email to recover</h2> 

      <p className="pera">
      Enter your email to proceed further
      </p>
    </div>
    <div className="form">
      <form >
        <div className="label">
          <label>Email</label>
        </div>
        <div className="field">
          <input
            type="email"
            required
            name="email"
            id="email"
          />
        </div>
        <div className="form-buttons">
          <Button type="submit">Recover</Button>
        </div>
      </form>
    </div>
  </div>
  )
}
