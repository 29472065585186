import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import "./Runs.css";
import "../common/common.css";
import { api, getAuthHeaders } from "../helpers";
import { RunsTable } from "./Table";
import { Crawler, NoData, RunInfo } from "../common/common";
import ReactPaginate from "react-paginate";

export const NextIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6.732"
    height="11.946"
    viewBox="0 0 6.732 11.946"
  >
    <path
      data-name="icon"
      d="m10261.883 144.184 4.561 4.318 4.559-4.318"
      transform="rotate(-90 5064.823 5207.592)"
      style={{
        fill: "none",
        stroke: "#8181a5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "2px",
      }}
    />
  </svg>
);

export const PrevIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6.732"
    height="11.946"
    viewBox="0 0 6.732 11.946"
  >
    <path
      data-name="icon"
      d="m10261.883 144.184 4.561 4.318 4.559-4.318"
      transform="rotate(90 5204.985 -5055.484)"
      style={{
        fill: "none",
        stroke: "#8181a5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "2px",
      }}
    />
  </svg>
);

export const Runs: React.FC = () => {
  const defaultCrawler = {
    value: "patek-watch-collector",
    label: "patek-watch-collector",
  };

  const [isLoading, setisLoading] = useState<boolean>(false);
  const [crawlers, setCrawlers] = useState<Crawler[]>([
    { name: "patek-watch-collector", slug: "Patek Watch" },
  ]);

  const [crawlerRuns, setCrawlerRuns] = useState<RunInfo[]>([]);

  const [selectedCrawler, setSelectedCrawler] = useState<string>(
    defaultCrawler.value
  );
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const options = crawlers.map((crawler) => ({
    value: crawler.slug,
    label: crawler.name,
  }));

  const fetchRuns = (page: number) => {
    setisLoading(true);
    const headers = getAuthHeaders();
    api
      .get(
        `/runs?module=${selectedCrawler}&page=${page}&page_size=${rowsPerPage}`,
        {
          headers,
        }
      )
      .then((resp) => {
        const { data, pagination } = resp.data;
        const { total_pages, total_items } = pagination;
        setTotalItems(total_items);
        setTotalPages(total_pages);
        setCrawlerRuns(resp.data.data);
        setisLoading(false);
      })
      .catch((e) => {
        setisLoading(false);
        alert(e);
      });
  };
  useEffect(() => {
    const headers = getAuthHeaders();
    api
      .get("/crawlers", { headers })
      .then((resp) => {
        setCrawlers(resp.data);
      })
      .catch((e) => {
        alert(e);
      });
  }, []);

  useEffect(() => {
    fetchRuns(page);
  }, [selectedCrawler]);

  const handleRefresh = () => {
    // Implement your refresh logic here
    fetchRuns(page);
  };

  const handleCrawlerChange = (values: any[]) => {
    // Check if the value array is not empty and has an element
    if (values.length > 0 && values[0]) {
      const selectedOption = values[0];
      setSelectedCrawler(selectedOption.value);
      // Fetch the runs for the selected crawler here, if necessary
    } else {
      setSelectedCrawler(defaultCrawler.value);
      // Optionally fetch runs for the default crawler here
    }
  };
  const handlePageClick = (data: { selected: number }) => {
    const newPageNumber = data.selected + 1;
    setPage(newPageNumber);
    fetchRuns(newPageNumber);
    // fetchDataForPage(newPageNumber);
  };
  return (
    <div className="runs-container">
      <div className="runs-header">
        <div className="crawlers-slector">
          <span style={{ fontWeight: "bold" }}>Crawlers</span>
          <Select
            className="crawler-select"
            onChange={handleCrawlerChange}
            options={options}
            values={options.filter(
              (option) => option.value === selectedCrawler
            )}
          />
        </div>

        <button className="refresh-button" onClick={handleRefresh}>
          Refresh
        </button>
      </div>
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      ) : crawlerRuns.length > 0 ? (
        <>
          <RunsTable runs={crawlerRuns} />
          <div className="pagination-controls">
            <ReactPaginate
              previousLabel={<PrevIcon />}
              nextLabel={<NextIcon />}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={page - 1} // to make sure the selected page matches the page in state
              disableInitialCallback={true} // to prevent onPageChange call after render
            />
          </div>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};
