import { ReactElement, useEffect, useState } from "react";
import { Runs } from "../runs/Runs";
import { Prices } from "../prices/Prices";
import { Comparables } from "../comparables/Comparables";
import { Upload } from "../upload/Uploads";
import "./Tabs.css";
import { getAPIKey, logout } from "../helpers";
import { TOKEN } from "../constants";
import { useNavigate } from "react-router-dom";
import { Verify } from "../verify/Verify";
import { ReactComponent as CorunSvg } from "../../images/runs.svg";
import { ReactComponent as ComparibleSvg } from "../../images/comparable.svg";
import { ReactComponent as VerifySvg } from "../../images/Verify.svg";
import { ReactComponent as PricesSvg } from "../../images/prices.svg";
import { ReactComponent as UploadSvg } from "../../images/ico.svg";
import { ReactComponent as LogoutSvg } from "../../images/logout_icon.svg";
import { ReactComponent as QuestionSvg } from "../../images/question.svg";
import ToolTip from "../common/Tooltip";

interface Tab {
  title: string;
  content: ReactElement;
  icon: ReactElement;
  query: string
}

interface TabsMap {
  [key: string]: Tab;
}

export const Tabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("runs");
  const navigate = useNavigate();
  useEffect(() => {
    //confirm login
    if (getAPIKey() != TOKEN) {
      navigate("/login");
    }
  }, []);

  const tabs: TabsMap = {
    runs: { title: "Runs", content: <Runs />, icon: <CorunSvg />, query: 'Get the list of runs for a given crawler.' },
    comparables: {
      title: "Comparables",
      content: <Comparables />,
      icon: <ComparibleSvg />,
      query: 'Compare valid values counts run with previous runs.'
    },
    prices: { title: "Prices", content: <Prices />, icon: <PricesSvg />, query: 'Compare prices per run per SKU with previous runs.' },
    verify: { title: "Verify", content: <Verify />, icon: <VerifySvg />, query: 'Verify programmatically the data quality of a run.' },
    upload: { title: "Upload", content: <Upload />, icon: <UploadSvg />, query: 'Upload the data of a run to the production S3 bucket.' },
  };
  //        'uploads': { 'title': 'Products', 'content': <Products /> }

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  return (
    <div className="main-wrapper">
      <div className="side-bar">
        <div>
          <div className="header-container">
            <div className="header-titles">
              <h1>Cartier Jewellery</h1>
            </div>
          </div>
          <ul className="tab-list">
            {Object.keys(tabs).map((tabKey) => {
              const tab = tabs[tabKey];
              return (
                <li
                  key={tab.title}
                  className={activeTab === tabKey ? "active-tab" : "tab"}
                  onClick={() => setActiveTab(tabKey)}
                >
                  <div className="tabs-list-icon">
                    <div>{tab?.icon}</div>
                    <div>{tab.title}</div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <button className="logout-button" onClick={handleLogout}>
          <LogoutSvg /> Logout
        </button>
      </div>  
      <div className="content-main-wrapper">
        <div className="content-main-title">
          {" "}
          <div className="content-main-title-icon">{tabs[activeTab].icon}</div>
          {activeTab} <ToolTip
        title={
          <div className="w-[382px] tooltiptxt">
            {tabs[activeTab].query}
          </div>
        }
        placement="top"
      >
        <QuestionSvg className="questionsmark" />
      </ToolTip>
        </div>
        {tabs[activeTab].content}
      </div>
    </div>
  );
};
