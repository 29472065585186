import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../common/common.css";
import { PriceItem } from "../common/common";
import TablePagination from "@mui/material/TablePagination";
import { ReactComponent as QuestionSvg } from "../../images/question.svg";
import ToolTip from "../common/Tooltip";

interface PriceProps {
  prices: PriceItem[];
  filter: boolean | null | "null";
}

export const PriceTable: React.FC<PriceProps> = ({ prices, filter }) => {
  if (typeof filter === "boolean" || filter == "null") {
    prices = prices.filter((price) => {
      // @ts-ignore
      if (filter === "null") {
        console.log(filter, 1);
        return price.is_valid === null;
      } else {
        console.log(filter, 2);
        return price.is_valid === filter;
      }
    });
  }
  console.log("in table rendering: ", prices);
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: "64vh", overflowY: "auto" }}
    >
      <Table className="runs-table" aria-label="simple table">
        <TableHead className="runs-table-head">
          <TableRow>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              SKU
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Currency
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Current
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Current price for a given SKU for a given run.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Previous
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Price for a given SKU for the previous run.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              AVG
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Average price for a given SKU over the last 3 valid runs.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              Low
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Average price *minus* 2 standard deviations.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              High
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    Average price *plus* 2 standard deviations.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
            <TableCell style={{ backgroundColor: "greenyellow" }}>
              OK{" "}
              <ToolTip
                title={
                  <div className="w-[382px] tooltiptxt">
                    True if the current price is between low and high; False
                    otherwise.
                  </div>
                }
                placement="top"
              >
                <QuestionSvg className="questionsmarkspace" />
              </ToolTip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prices.map((price, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {price.sku}
              </TableCell>
              <TableCell>{price.currency}</TableCell>
              <TableCell>
                {price.value !== undefined && price.value !== null
                  ? price.value
                  : "NULL"}
              </TableCell>
              <TableCell>
                {price.previous !== undefined && price.previous !== null
                  ? Math.round(Number(price.previous.toFixed(2)))
                  : "NULL"}
              </TableCell>
              <TableCell>
                {price.average !== undefined && price.average !== null
                  ? Math.round(Number(price.average.toFixed(2)))
                  : "NULL"}
              </TableCell>
              <TableCell>
                {price.low !== undefined && price.low !== null
                  ? Math.round(Number(price.low.toFixed(2)))
                  : "NULL"}
              </TableCell>
              <TableCell>
                {price.high !== undefined && price.high !== null
                  ? Math.round(Number(price.high.toFixed(2)))
                  : "NULL"}
              </TableCell>

              <TableCell
                style={{
                  fontWeight: price.is_valid ? "normal" : "bold",
                  color: price.is_valid ? "lightgreen" : "red",
                }}
              >
                <span
                  className={
                    price.is_valid
                      ? "change-clr"
                      : price.is_valid == false
                      ? "clr-red"
                      : "review-clr"
                  }
                >
                  {price.is_valid
                    ? "True"
                    : price.is_valid == false
                    ? "False"
                    : "NULL"}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
