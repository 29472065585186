import { useEffect, useState } from "react";
import "./Comparables.css";
import { ComparableItem, Crawler, NoData, RunInfo } from "../common/common";
import { api, getAuthHeaders } from "../helpers";
import Select from "react-dropdown-select";
import { ComparableTable } from "./Table";
import ReactPaginate from "react-paginate";
import { PrevIcon, NextIcon } from "../runs/Runs";

export const Comparables = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [comparablesData, setComparablesData] = useState<ComparableItem[]>([]);
  const [filteredData, setFilteredData] = useState<ComparableItem[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [crawlers, setCrawlers] = useState<Crawler[]>([
    { name: "patek-watch-collector", slug: "Patek Watch" },
  ]);

  const [selectedAttribute, setSelectedAttribute] = useState<string>();
  const [selectedCrawler, setselectedCrawler] = useState<string>();

  const crawlerOptions = crawlers.map((option) => ({
    value: option.slug,
    label: option.name,
  }));

  const onCrawlerChange = (values: any[]) => {
    if (values.length > 0 && values[0]) {
      let s = values[0];
      setselectedCrawler(s.value);
    }
  };

  const [crawlerRuns, setCrawlerRuns] = useState<RunInfo[]>([
    {
      run_id: "",
      started_at: "",
      ended_at: "",
      status: "",
      started_at_date: "",
      ended_at_date: "",
      is_verified: false,
      module: "",
      uploaded: true,
      errors: [],
    },
  ]);

  const runOptions = crawlerRuns.map((run) => ({
    value: run.run_id,
    label: run.started_at_date,
  }));

  const [selectedRun, setSelectedRun] = useState<String>();

  const onRunChange = (values: any[]) => {
    if (values.length > 0 && values[0]) {
      const run = crawlerRuns.find(
        (run) => run.started_at_date === values[0].label
      );
      if (run) {
        setSelectedRun(run.run_id);
      }
    }
  };
  const valueOptions = [
    { value: "all", label: "All" },
    { value: "true", label: "True" },
    { value: "false", label: "False" },
    { value: "null", label: "Null" },
  ];

  const [valueOption, setValueOption] = useState<any>(null);

  const handleValueChange = (value: any) => {
    if (value[0]?.value == "all") {
      setValueOption(null);
    } else {
      setValueOption(value[0]?.value);
    }
  };

  const fetchDataForPage = (page: number) => {
    setisLoading(true);
    const headers = getAuthHeaders();
    api
      .get(
        `/comparable?run=${selectedRun}&page=${page}&page_size=${rowsPerPage}`,
        { headers }
      )
      .then((resp) => {
        const { data, pagination } = resp.data;
        const { total_pages, total_items } = pagination;
        setTotalItems(total_items);
        setTotalPages(total_pages);
        const transformedData: ComparableItem[] = Object.entries(data).map(
          ([key, value]: [string, any]) => {
            return {
              attribute: key,
              empty: value.empty,
              zero: value.zero,
              null: value.null,
              value: value.current,
              previous: value.previous,
              average: value.average,
              low: value.low,
              high: value.high,
              is_valid: value.is_valid,
            };
          }
        );
        setComparablesData(transformedData);
        setisLoading(false);
      })
      .catch((e) => {
        setisLoading(false);
        alert(e);
      });
  };

  useEffect(() => {
    if (selectedRun) {
      fetchDataForPage(1);
    }
  }, [selectedRun]);

  useEffect(() => {
    let data = comparablesData;
    console.log("dsdsds", data);
    if (selectedAttribute) {
      data = data.filter((d) => d.attribute == selectedAttribute);
    }
    setFilteredData(data);
  }, [comparablesData, selectedAttribute, selectedRun]);

  const fetchRuns = () => {
    setisLoading(true);
    if (selectedCrawler) {
      const headers = getAuthHeaders();
      api
        .get(`/runs?module=${selectedCrawler}`, { headers })
        .then((resp) => {
          if (resp.data.data.length > 0) {
            setCrawlerRuns(resp.data.data);
            setSelectedRun(resp.data.data[0].run_id);
          } else {
            setCrawlerRuns([]);
            setSelectedRun("");
            setComparablesData([]);
          }
          setisLoading(false);
        })
        .catch((e) => {
          setisLoading(false);
          alert(e);
        });
    }
  };

  useEffect(() => {
    setisLoading(true);
    const headers = getAuthHeaders();
    api
      .get("/crawlers", { headers })
      .then((resp) => {
        const crawlersData: Crawler[] = resp.data;
        setCrawlers(crawlersData);
        setselectedCrawler(crawlersData[0].slug);
      })
      .catch((e) => {
        setisLoading(false);
        alert(e);
      });
  }, []);

  useEffect(() => {
    fetchRuns();
  }, [crawlers, selectedCrawler]);

  const handleRefresh = () => {
    fetchRuns();
  };

  const allOption = { value: "all", label: "All" };
  const attributeOptions = [
    allOption,
    ...Array.from(
      new Set(comparablesData.map((option) => option.attribute))
    ).map((attribute) => ({
      value: attribute,
      label: attribute,
    })),
  ];
  const onAttributeChange = (values: any[]) => {
    if (values.length > 0 && values[0]) {
      setSelectedAttribute(values[0].value === "all" ? null : values[0].value);
    }
  };

  const handlePageClick = (data: { selected: number }) => {
    const newPageNumber = data.selected + 1;
    setPage(newPageNumber);
    fetchDataForPage(newPageNumber);
  };

  return (
    <div className="comparables-container">
      <div className="comparables-header">
        <div className="comparable-select-box">
          <div className="dropdown-with-title">
            <span className="dropdown-title">Crawlers</span>
            <Select
              onChange={onCrawlerChange}
              options={crawlerOptions}
              values={crawlerOptions.filter(
                (option) => option.value === selectedCrawler
              )}
              disabled={isLoading}
            />
          </div>
          <div className="dropdown-with-title">
            <span className="dropdown-title">Run</span>
            <Select
              onChange={onRunChange}
              options={runOptions}
              values={runOptions.filter(
                (option) => option.value === selectedRun
              )}
              disabled={isLoading}
            />
          </div>
          <div className="dropdown-with-title">
            <span className="dropdown-title">Attribute</span>
            <Select
              onChange={onAttributeChange}
              options={attributeOptions}
              values={attributeOptions.filter(
                (option) => option.value === selectedAttribute
              )}
            />
          </div>
          <div className="dropdown-with-title">
            <div className="dropdown-title">OK</div>

            <Select
              onChange={handleValueChange}
              options={valueOptions}
              values={valueOptions.filter(
                (option) => option.value === valueOption
              )}
            />
          </div>
        </div>
        <button className="refresh-button" onClick={handleRefresh}>
          Refresh
        </button>
      </div>
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      ) : filteredData.length > 0 ? (
        <>
          <ComparableTable
            comparables={filteredData}
            filter={
              valueOption == "true"
                ? true
                : valueOption == "false"
                ? false
                : valueOption == "null"
                ? "null"
                : null
            }
          />
          <div className="pagination-controls">
            <ReactPaginate
              previousLabel={<PrevIcon />}
              nextLabel={<NextIcon />}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={page - 1}
              disableInitialCallback={true}
            />
          </div>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};
