import axios from "axios";
import Cookies from "js-cookie";
import { TOKEN_KEY } from "./constants";

export const getAuthHeaders = () => {
  return { Authorization: `59a4f06fd4e89466c00dfd5ce7242ab4eab1f599` };
};

export const api = axios.create({
  baseURL: "https://api.cartier.lobstr.io/v1",
});

export const getAPIKey = () => {
  return Cookies.get(TOKEN_KEY);
};

export const storeAPIKey = (apiKey: string) => {
  Cookies.set(TOKEN_KEY, apiKey);
};

export const logout = () => {
  Cookies.remove(TOKEN_KEY);
};
