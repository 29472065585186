import React, { useState, FormEvent, useEffect } from "react";
import "./Login.css"; // Make sure to create a corresponding CSS file
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { api, getAPIKey, storeAPIKey } from "../helpers";
import { TOKEN } from "../constants";
import Button from "../common/Buttons/Button";
import Checkbox from "@mui/material/Checkbox";
import check from "../../images/check.svg";
export const LoginComponent: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkbox, setCheckbox] = useState(true);
  console.log(checkbox, "checkbox");

  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("checking api key...");
    if (getAPIKey() == TOKEN) {
      navigate("/");
    }
  }, []);

  const handleSubmit = (event: FormEvent) => {
    setisLoading(true);
    event.preventDefault();
    api
      .post("/login/", { email, password })
      .then((resp) => {
        setisLoading(false);
        //redirect to runs page
        storeAPIKey(resp.data.token);
        navigate("/");
      })
      .catch((e) => {
        setisLoading(false);
        console.log(e);
        alert("login failed");
      });
  };

  const handleNavigate = () => {
    navigate("/recover-password");
  };

  const handleNavigateSignUp = () => {
    navigate("/sign-up");
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckbox(event.target.checked);
  };

  return (
    <div className="logincontainer">
      <div className="main">
        <h2>Sign In</h2>
        <p className="pera">Enter your details to proceed further</p>
      </div>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="label">
            <label>Email</label>
          </div>
          <div className="field">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              name="email"
              id="email"
            />
          </div>
          <div className="label">
            <label>Password</label>
          </div>
          <div className="field">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              name="password"
              id="password"
            />
          </div>
          <div className="remember" onClick={() => setCheckbox((p) => !p)}>
            <div className="check">
              {checkbox && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="text-sm h-5"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              )}{" "}
            </div>
            Remember me
          </div>

          <div className="form-buttons">
            <Button type="submit">Sign In</Button>
            {/* <Button variant="outline" onClick={handleNavigateSignUp}>Sign Up</Button> */}
          </div>
        </form>
      </div>
    </div>
  );
};
