import { useEffect, useState } from "react";
import "../common/common.css";
import { Crawler, PriceInfo, PriceItem, RunInfo } from "../common/common";
import { api, getAuthHeaders } from "../helpers";
import Select from "react-dropdown-select";
import { PriceTable } from "./Table";
import ReactPaginate from "react-paginate";
import { PrevIcon, NextIcon } from "../runs/Runs";

interface PricesData {
  [key: string]: PriceInfo;
}

interface CustomPaginationProps {
  total_pages: number;
  onChangePage: (page: number) => void;
  currentPage: number;
  isLoading: boolean;
}

type PageClickEvent = {
  selected: number;
};

export const Prices = () => {
  const [selectedSKU, setSelectedSKU] = useState<string>();
  const [selectedCurrency, setSelectedCurrency] = useState<string>();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [pricesData, setpricesData] = useState<PriceItem[]>([]);
  const [filteredData, setFilteredData] = useState<PriceItem[]>([]);
  //track currently selected run
  const [selectedRun, setSelectedRun] = useState<String>();
  // Add state for pagination
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const allOption = { value: "all", label: "All" };
  // @ts-ignore
  const skuOptions = [
    allOption,
    ...Array.from(new Set(pricesData.map((option) => option.sku))).map(
      (sku) => ({
        value: sku,
        label: sku,
      })
    ),
  ];

  const currencyOptions = [
    allOption,
    ...Array.from(new Set(pricesData.map((option) => option.currency))).map(
      (currency) => ({
        value: currency,
        label: currency.toUpperCase(),
      })
    ),
  ];

  const onSKUChange = (values: any[]) => {
    if (values.length > 0 && values[0]) {
      setSelectedSKU(values[0].value === "all" ? null : values[0].value);
    }
  };

  const onCurrencyChange = (values: any[]) => {
    if (values.length > 0 && values[0]) {
      setSelectedCurrency(values[0].value === "all" ? null : values[0].value);
    }
  };
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangeRowsPerPage = (values: any[]) => {
    if (values.length > 0 && values[0]) {
      const newRowsPerPage = values[0].value;
      setRowsPerPage(newRowsPerPage);
      setPage(1);
      fetchDataForPage(1);
    }
  };

  //crawlers fetched from api
  const [crawlers, setCrawlers] = useState<Crawler[]>([
    { name: "patek-watch-collector", slug: "Patek Watch" },
  ]);

  //currently selected crawler
  const [selectedCrawler, setselectedCrawler] = useState<string>();

  //crawler drop down options
  const crawlerOptions = crawlers.map((option) => ({
    value: option.slug,
    label: option.name,
  }));

  const resetStateVars = () => {
    setSelectedCurrency("");
    setSelectedSKU("");
    setValueOption(null);
  };
  const onCrawlerChange = (values: any[]) => {
    //reset vars
    resetStateVars();
    setPage(1);
    setValueOption(null);
    if (values.length > 0 && values[0]) {
      let s = values[0];
      setselectedCrawler(s.value);
    }
  };

  //crawler runs fetched from the api
  const [crawlerRuns, setCrawlerRuns] = useState<RunInfo[]>([
    {
      run_id: "",
      started_at: "",
      ended_at: "",
      status: "",
      started_at_date: "",
      ended_at_date: "",
      is_verified: false,
      module: "",
      uploaded: false,
      errors: [],
    },
  ]);

  //run dropped down options
  const runOptions = crawlerRuns.map((run) => ({
    value: run.run_id,
    label: run.started_at_date,
  }));

  const onRunChange = (values: any[]) => {
    //find the run whose started at date is the same as the value
    //retrieve comparables for that run
    //during this fetch process , UI should not be selectable
    resetStateVars();
    setPage(1);
    if (values.length > 0 && values[0]) {
      const run = crawlerRuns.find(
        (run) => run.started_at_date === values[0].label
      );
      if (run) {
        setSelectedRun(run.run_id);
      }
    }
  };

  const fetchDataForPage = (page: number) => {
    console.log(`fetching page data for: `, page);
    setisLoading(true);
    const headers = getAuthHeaders();
    api
      .get(`/prices?run=${selectedRun}&page=${page}&page_size=${rowsPerPage}`, {
        headers,
      })
      .then((resp) => {
        console.log("resp", resp.data);
        const { data, pagination } = resp.data;
        const { total_pages, total_items } = pagination;
        setTotalItems(total_items);
        setTotalPages(total_pages);
        const transformedData: PriceItem[] = [];
        Object.entries(data).forEach(([key, value]) => {
          // Here's the key change: assert the type of `value` to be PricesData
          const prices: PricesData = value as PricesData;

          Object.entries(prices).forEach(([currencyKey, priceInfo]) => {
            transformedData.push({
              sku: key,
              currency: currencyKey,
              value: priceInfo.current,
              previous: priceInfo.previous,
              average: priceInfo.average,
              low: priceInfo.low,
              high: priceInfo.high,
              is_valid: priceInfo.is_valid,
            });
          });
        });
        console.log("prices data: ", transformedData);
        setpricesData(transformedData);
        setisLoading(false);
      })
      .catch((e) => {
        setisLoading(false);
        alert(e);
      });
  };
  useEffect(() => {
    if (selectedRun) {
      fetchDataForPage(1);
    }
  }, [selectedRun]);

  const valueOptions = [
    { value: "all", label: "All" },
    { value: "true", label: "True" },
    { value: "false", label: "False" },
    { value: "null", label: "Null" },
  ];

  const [valueOption, setValueOption] = useState<any>(null);

  const handleValueChange = (value: any) => {
    if (value[0]?.value == "all") {
      setValueOption(null);
    } else {
      setValueOption(value[0]?.value);
    }
  };

  const fetchRuns = () => {
    if (selectedCrawler) {
      const headers = getAuthHeaders();
      api
        .get(`/runs?module=${selectedCrawler}`, { headers })
        .then((resp) => {
          if (resp.data.data.length > 0) {
            setCrawlerRuns(resp.data.data);
            setSelectedRun(resp.data.data[0].run_id);
          }
        })
        .catch((e) => {
          alert(e);
        });
    }
  };
  useEffect(() => {
    const headers = getAuthHeaders();
    api
      .get("/crawlers", { headers })
      .then((resp) => {
        // Assure TypeScript that resp.data is an array of Crawler objects
        const crawlersData: Crawler[] = resp.data;
        setCrawlers(crawlersData);
        setselectedCrawler(crawlersData[0].slug);
      })
      .catch((e) => {
        alert(e);
      });
  }, []);

  useEffect(() => {
    fetchRuns();
  }, [crawlers, selectedCrawler]);

  useEffect(() => {
    let data = pricesData;
    console.log(
      `selectedSKU ${selectedSKU} selectedCurrency ${selectedCurrency}`
    );
    if (selectedSKU) {
      data = data.filter((item) => item.sku === selectedSKU);
      console.log(`selected sku  ${selectedSKU} filtered data ${data}`);
    }
    if (selectedCurrency) {
      data = data.filter((item) => item.currency === selectedCurrency);
    }
    console.log("setting filtered data: ", data);
    if (data.length > 0) {
      setFilteredData(data);
    } else {
      setFilteredData([]);
    }
  }, [selectedSKU, selectedCurrency, pricesData]);

  const handlePageClick = (data: PageClickEvent) => {
    //reset vars
    resetStateVars();
    const newPageNumber = data.selected + 1; // react-paginate is 0-indexed
    setPage(newPageNumber);
    fetchDataForPage(newPageNumber);
  };

  return (
    <div className="comparables-container">
      <div className="comparables-header">
        <div className="comparable-select-box">
          <div className="dropdown-with-title">
            <div className="dropdown-title">Crawlers</div>
            <Select
              onChange={onCrawlerChange}
              options={crawlerOptions}
              values={crawlerOptions.filter(
                (option) => option.value === selectedCrawler
              )}
              disabled={isLoading}
            />
          </div>
          <div className="dropdown-with-title">
            <div className="dropdown-title">Run</div>
            <Select
              onChange={onRunChange}
              options={runOptions}
              values={runOptions.filter(
                (option) => option.value === selectedRun
              )}
              disabled={isLoading}
            />
          </div>

          <div className="dropdown-with-title">
            <div className="dropdown-title">SKU</div>
            <Select
              onChange={onSKUChange}
              options={skuOptions}
              values={skuOptions.filter(
                (option) => option.value === selectedSKU
              )}
            />
          </div>
          <div className="dropdown-with-title">
            <div className="dropdown-title">Currency</div>
            <Select
              onChange={onCurrencyChange}
              options={currencyOptions}
              values={currencyOptions.filter(
                (option) => option.value === selectedCurrency
              )}
            />
          </div>
          <div className="dropdown-with-title">
            <div className="dropdown-title">OK</div>
            {/* {valueOptions.map((option, idx) => (
              <label key={idx} className="value-option">
                <input
                  type="radio"
                  name="value"
                  value={option.value ? "True" : "False"}
                  checked={valueOption === option.value}
                  onChange={() => handleValueChange(option.value)}
                />
                {option.label}
              </label>
            ))} */}
            <Select
              onChange={handleValueChange}
              options={valueOptions}
              values={valueOptions.filter(
                (option) => option.value === valueOption
              )}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <>
          <PriceTable
            prices={filteredData}
            filter={
              valueOption == "true"
                ? true
                : valueOption == "false"
                ? false
                : valueOption == "null"
                ? "null"
                : null
            }
          />
          <div className="pagination-controls">
            <ReactPaginate
              previousLabel={<PrevIcon />}
              nextLabel={<NextIcon />}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={page - 1} // to make sure the selected page matches the page in state
              disableInitialCallback={true} // to prevent onPageChange call after render
            />
          </div>
        </>
      )}
    </div>
  );
};
