import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import "./Uploads.css";
import "../common/common.css";
import { api, getAuthHeaders } from "../helpers";
import { Crawler, NoData, RunInfo } from "../common/common";
import { UploadTable } from "./Table";
import ReactPaginate from "react-paginate";
import { NextIcon, PrevIcon } from "../runs/Runs";

export const Upload: React.FC = () => {
  const defaultCrawler = {
    value: "all",
    label: "All",
  };

  const [isLoading, setisLoading] = useState<boolean>(false);
  const [crawlers, setCrawlers] = useState<Crawler[]>();

  const [crawlerRuns, setCrawlerRuns] = useState<RunInfo[]>([]);
  const [filteredRuns, setFilteredRuns] = useState<RunInfo[]>([]);

  const [selectedCrawler, setSelectedCrawler] = useState<string>(
    defaultCrawler.value
  );
  const options = (crawlers || []).map((crawler) => ({
    value: crawler.slug,
    label: crawler.name,
  }));

  const defaultPeriod = {
    value: "latest",
  };

  const [periods, setPeriods] = useState<string[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(
    defaultPeriod.value
  );

  const periodOptions = (periods || []).map((period) => ({
    value: period,
    label: period,
  }));

  const [selectedRuns, setSelectedRuns] = useState<string[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const FetchPeriodRuns = async (page: number) => {
    setisLoading(true);
    const headers = getAuthHeaders();
    api
      .get(`/period-runs?module=${selectedCrawler}&period=${selectedPeriod}`, {
        headers,
      })
      .then((resp) => {
        const { data, pagination } = resp.data;
        const { total_pages, total_items } = pagination;
        setTotalItems(total_items);
        setTotalPages(total_pages);
        setCrawlerRuns(resp.data.data);
        setFilteredRuns(resp.data.data);
        setisLoading(false);
      })
      .catch((e) => {
        alert(e);
        setisLoading(false);
      });
  };

  useEffect(() => {
    if (selectedCrawler.toLowerCase() === "all") {
      setFilteredRuns(crawlerRuns);
    } else {
      const option = options.filter((op) => op.value === selectedCrawler);
      if (crawlerRuns.length > 0 && option.length > 0) {
        setFilteredRuns(
          crawlerRuns.filter((run) => run.module === option[0].value)
        );
      }
    }
  }, [selectedCrawler]);

  useEffect(() => {
    const headers = getAuthHeaders();
    api
      .get("/crawlers", { headers })
      .then((resp) => {
        setCrawlers([{ name: "All", slug: "all" }, ...resp.data]);
      })
      .catch((e) => {
        alert(e);
      });
  }, []);

  useEffect(() => {
    const headers = getAuthHeaders();
    setisLoading(true);
    api
      .get("/run/periods", { headers })
      .then((resp) => {
        setPeriods([defaultPeriod.value, ...resp.data]);
        setSelectedPeriod(defaultPeriod.value);
        setisLoading(false);
      })
      .catch((e) => {
        alert(e);
        setisLoading(false);
      });
  }, [crawlers]);

  useEffect(() => {
    if (selectedPeriod) {
      FetchPeriodRuns(page);
    }
  }, [selectedPeriod, selectedCrawler]);

  const handleRefresh = () => {
    FetchPeriodRuns(page);
  };

  const handleUpload = () => {
    //get all runs ids
    if (selectedRuns.length) {
      setisLoading(true);
      const headers = getAuthHeaders();
      api
        .put(
          "/upload-runs",
          {
            run_ids: selectedRuns,
          },
          { headers }
        )
        .then((_) => {
          FetchPeriodRuns(page);
        })
        .catch((e) => {
          setisLoading(false);
          alert(e);
        });
    }
  };

  const handleCrawlerChange = (values: any[]) => {
    // Check if the value array is not empty and has an element
    if (values.length > 0 && values[0]) {
      const selectedOption = values[0];
      setSelectedCrawler(selectedOption.value);
    } else {
      setSelectedCrawler(defaultCrawler.value);
    }
  };

  const handlePeriodChange = (values: any[]) => {
    if (values.length > 0 && values[0]) {
      const selectedOption = values[0];
      setSelectedPeriod(selectedOption.value);
    }
  };

  useEffect(() => {
    setSelectedRuns(filteredRuns.map((run: RunInfo) => run.run_id));
  }, [filteredRuns]);
  const handlePageClick = (data: { selected: number }) => {
    const newPageNumber = data.selected + 1;
    setPage(newPageNumber);
    FetchPeriodRuns(newPageNumber);
    // fetchDataForPage(newPageNumber);
  };
  return (
    <div className="upload-container">
      <div className="upload-header">
        <div className="upload-select-box">
          <div>
            <span>Crawlers</span>
            <Select
              className="crawler-select"
              onChange={handleCrawlerChange}
              options={options}
              values={options.filter(
                (option) => option.value === selectedCrawler
              )}
            />
          </div>

          <div>
            <span>Run</span>
            <Select
              className="crawler-select"
              onChange={handlePeriodChange}
              options={periodOptions}
              values={periodOptions.filter(
                (option) => option.value === selectedPeriod
              )}
            />
          </div>
        </div>

        <div className="upload-header-buttons">
          <button className="refresh-button" onClick={handleRefresh}>
            Refresh
          </button>
          <button className="refresh-button" onClick={handleUpload}>
            Upload
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      ) : crawlerRuns.length > 0 ? (
        <>
          <UploadTable
            runs={filteredRuns}
            selectedRuns={selectedRuns}
            setSelectedRuns={setSelectedRuns}
          />
          <div className="pagination-controls">
            <ReactPaginate
              previousLabel={<PrevIcon />}
              nextLabel={<NextIcon />}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={page - 1} // to make sure the selected page matches the page in state
              disableInitialCallback={true} // to prevent onPageChange call after render
            />
          </div>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};
